import Box from '@mui/material/Box'
import Lines from '../../assets/ve-lines.png'
import Dots from '../../assets/ve-dots.png'

const LINES_IMAGE_WIDTH = 425
const LINES_IMAGE_HEIGHT = 585
const LINES_SCALING = 1350 * 1.1

const DOTS_IMAGE_WIDTH = 392
const DOTS_IMAGE_HEIGHT = 247
const DOTS_SCALING = 1150 * 1.2

interface Props {
  dashboardWidth: number
}

export function VisualEffects({ dashboardWidth }: Props): JSX.Element {
  const linesWidth = Math.round((dashboardWidth * LINES_IMAGE_WIDTH) / LINES_SCALING)
  const linesHeight = Math.round((dashboardWidth * LINES_IMAGE_HEIGHT) / LINES_SCALING)
  const linesPositionTop = Math.round(dashboardWidth * 0.005)
  const linesPositionLeft = Math.round(dashboardWidth * 0.705)

  const dotsWidth = Math.round((dashboardWidth * DOTS_IMAGE_WIDTH) / DOTS_SCALING)
  const dotsHeight = Math.round((dashboardWidth * DOTS_IMAGE_HEIGHT) / DOTS_SCALING)
  const dotsPositionBottom = Math.round(dashboardWidth * 0.03)
  const dotsPositionLeft = Math.round(dashboardWidth * 0.03)

  return (
    <>
      <Box
        component='img'
        sx={{
          position: 'absolute',
          left: linesPositionLeft,
          top: linesPositionTop,
          width: linesWidth,
          height: linesHeight,
        }}
        src={Lines}
      />
      <Box
        component='img'
        sx={{
          position: 'absolute',
          left: dotsPositionLeft,
          bottom: dotsPositionBottom,
          height: dotsHeight,
          width: dotsWidth,
        }}
        src={Dots}
      />
    </>
  )
}
