import useSWRImmutable, { SWRResponse } from 'swr'
import { useQuery } from '../common-code-react/hooks/useQuery'
import { parseUrlQueryParams } from '../utils/parseUrlQueryParams'
import { LanguageInfo } from '../common-code-react/common-types/modules/languageInfo'
import { fetcher } from '../common-code-react/utils/fetcher'

export function useLanguage(): SWRResponse<LanguageInfo> {
  const query = useQuery()
  const queryParams = parseUrlQueryParams(query)

  const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || '' : ''
  const url = `/api/language/${queryParams.dashboardCode || ''}`
  const fullUrl = queryParams.dashboardCode && !queryParams.lang ? encodeURI(`${baseUrl}${url}`) : null

  return useSWRImmutable(fullUrl, fetcher<LanguageInfo>())
}
