import { TFunction } from 'i18next'
import {
  DEFAULT_THRESHOLD_AVERAGE,
  DEFAULT_THRESHOLD_EXCELLENT,
  DEFAULT_THRESHOLD_FAIR,
  DEFAULT_THRESHOLD_GOOD,
} from '../constants/defaults'

export function getLevelMessage(airQuality: number, t: TFunction<'translation', undefined>): string {
  if (airQuality > DEFAULT_THRESHOLD_EXCELLENT) {
    return t('Excellent_Message')
  }
  if (airQuality > DEFAULT_THRESHOLD_GOOD) {
    return t('Good_Message')
  }
  if (airQuality > DEFAULT_THRESHOLD_AVERAGE) {
    return t('Average_Message')
  }
  if (airQuality > DEFAULT_THRESHOLD_FAIR) {
    return t('Fair_Message')
  }
  return t('Poor_Message')
}

export function getLevelText(airQuality: number, t: TFunction<'translation', undefined>): string {
  if (airQuality > DEFAULT_THRESHOLD_EXCELLENT) {
    return t('Excellent')
  }
  if (airQuality > DEFAULT_THRESHOLD_GOOD) {
    return t('Good')
  }
  if (airQuality > DEFAULT_THRESHOLD_AVERAGE) {
    return t('Average')
  }
  if (airQuality > DEFAULT_THRESHOLD_FAIR) {
    return t('Fair')
  }
  return t('Poor')
}
