import { Box, Typography } from '@mui/material'
import { useCityConnectData } from '../hooks/useCityConnectData'
import { useTranslation } from 'react-i18next'
import useDashboardSize from '../common-code-dashboards/hooks/useDashboardSize'
import { DarkBoxWithPosition } from '../common-code-dashboards/components/DarkBoxWithPosition'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'

export function TempHumCo2(): JSX.Element | null {
  const { dashboardWidth } = useDashboardSize()
  const { fontSizeTranslation } = useFontSizeTranslation()

  const { t } = useTranslation()
  const { cityConnectData } = useCityConnectData()

  const paddingTopBottom = `${(dashboardWidth * 10) / DEFAULT_WIDTH}px`
  const paddingSides = `${(dashboardWidth * 10) / DEFAULT_WIDTH}px`
  const co2Height = `${(dashboardWidth * 260) / DEFAULT_WIDTH}px`

  if (!cityConnectData) {
    return null
  }

  return (
    <>
      <DarkBoxWithPosition
        boxWidth={480 / 2 - 30}
        positionLeft={2320}
        positionTop={1205}
        sx={{ padding: `${paddingTopBottom} ${paddingSides}` }}
      >
        <Typography
          sx={{
            fontSize: fontSizeTranslation('30px'),
          }}
        >
          {t('Temperature')}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('60px'),
            lineHeight: 1,
            justifySelf: 'end',
          }}
        >
          {cityConnectData.temp ? (
            <>
              {cityConnectData.temp.toFixed(0)}
              <span>&deg;</span>C
            </>
          ) : (
            '-'
          )}
        </Typography>
      </DarkBoxWithPosition>

      <DarkBoxWithPosition
        boxWidth={480 / 2 - 30}
        positionLeft={2320}
        positionTop={1340}
        sx={{ padding: `${paddingTopBottom} ${paddingSides}` }}
      >
        <Typography
          sx={{
            fontSize: fontSizeTranslation('30px'),
          }}
        >
          {t('Humidity')}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('60px'),
            lineHeight: 1,
            justifySelf: 'end',
          }}
        >
          {cityConnectData.hum ? `${cityConnectData.hum.toFixed(0)}%` : '-'}
        </Typography>
      </DarkBoxWithPosition>
      <DarkBoxWithPosition
        boxWidth={480 / 2 + 30}
        positionLeft={2320 + (480 / 2 - 30) + 10}
        positionTop={1205}
        sx={{
          padding: `${paddingTopBottom} ${paddingSides}`,
          height: co2Height,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: fontSizeTranslation('30px'),
              }}
            >
              {t('CO2')}
            </Typography>
            <Typography
              sx={{
                fontSize: fontSizeTranslation('30px'),
              }}
            >
              CO
              <Typography component='span' fontSize={fontSizeTranslation('24px')}>
                2
              </Typography>
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: fontSizeTranslation('60px'),
            }}
          >
            {cityConnectData.co2 ? (
              <>
                {cityConnectData.co2.toFixed(0)}
                <Typography component='span' fontSize={fontSizeTranslation('24px')}>
                  PPM
                </Typography>
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Box>
      </DarkBoxWithPosition>
    </>
  )
}
