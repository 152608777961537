import { useState, useEffect } from 'react'
import { useCityConnectData } from './useCityConnectData'
import { calculateIAQ } from '../utils/iaqUtils'
import * as Constants from '../constants'
import { CityConnectDeviceData } from '../common-code-react/common-types/products/cityConnect'

export interface IaqData {
  iaq: number
  timestamp: number
}

interface AirQualityReturnData {
  airQuality: number | undefined
  isLoading: boolean
  isError: boolean
}

export function useCalculatedAirQuality(): AirQualityReturnData {
  const [dataList, setDataList] = useState<CityConnectDeviceData[]>([])
  const [airQuality, setAirQuality] = useState<number | undefined>()

  const { cityConnectData, isLoading, isError } = useCityConnectData()

  useEffect(() => {
    console.log(`cityConnectData: ${JSON.stringify(cityConnectData)}`)
    if (!cityConnectData || !cityConnectData.utc) {
      setDataList([])
    } else if (!dataList.map((data) => data.utc).includes(cityConnectData.utc)) {
      setDataList([...dataList, cityConnectData])
    }
  }, [cityConnectData])

  useEffect(() => {
    console.log(`dataList: ${JSON.stringify(dataList)}`)

    if (dataList.length === 0) {
      setAirQuality(undefined)
    } else {
      const pm25 = dataList.map((data) => data.pm2_5 || 0).reduce((a, b) => a + b) / dataList.length
      const pm1num = dataList.map((data) => data.pm1_num || 0).reduce((a, b) => a + b) / dataList.length
      const voc = dataList.map((data) => data.voci || 0).reduce((a, b) => a + b) / dataList.length
      const average = calculateIAQ({
        pm25,
        pm1num,
        voc,
      })
      setAirQuality(average)

      if (dataList.length > 1) {
        const secondsDiff = Math.abs(dataList[dataList.length - 1].utc - dataList[0].utc)
        if (secondsDiff > Constants.AVERAGE_QUALITY_CIRCLE_TIMEOUT_MILLIS / 1000) {
          const [, ...tail] = dataList
          setDataList(tail)
        }
      }
    }
  }, [dataList])

  return {
    airQuality: airQuality,
    isLoading,
    isError,
  }
}
