import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DEFAULT_WIDTH } from '../constants/defaults'
import useDashboardSize from '../hooks/useDashboardSize'
import { useFontSizeTranslation } from '../hooks/useFontSizes'
import { getColor } from '../utils/getColor'
import { getLevelText } from '../utils/levelUtils'

export interface Props {
  airQualityPercentage: number
  fontSize: string
}

export function ColoredQualityLabel({ airQualityPercentage, fontSize }: Props): JSX.Element | null {
  const { fontSizeTranslation } = useFontSizeTranslation()
  const { t } = useTranslation()
  const { dashboardWidth } = useDashboardSize()
  const paddingSides = `${(dashboardWidth * 36) / DEFAULT_WIDTH}px`
  const borderRadius = `${(dashboardWidth * 80) / DEFAULT_WIDTH}px`

  const fontSizeNumberValue = parseFloat(fontSize.split('px')[0])
  const lineHeightNumberValue = fontSizeNumberValue * 1.4

  return (
    <Box justifyContent='center' display='flex'>
      <Typography
        align='center'
        sx={{
          fontSize: fontSizeTranslation(fontSize),
          lineHeight: fontSizeTranslation(`${lineHeightNumberValue}px`),
          background: getColor(airQualityPercentage, true),
          borderRadius,
          paddingLeft: paddingSides,
          paddingRight: paddingSides,
        }}
      >
        {getLevelText(airQualityPercentage, t)}
      </Typography>
    </Box>
  )
}
