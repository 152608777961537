import { useWindowSize } from 'usehooks-ts'
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../constants/defaults'

export default function useDashboardSize() {
  const { width, height } = useWindowSize()

  const DEFAULT_WIDTH_HEIGHT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT

  const widerThanRatio = width / height > DEFAULT_WIDTH_HEIGHT_RATIO
  const dashboardWidth = widerThanRatio ? height * DEFAULT_WIDTH_HEIGHT_RATIO : width
  const dashboardHeight = widerThanRatio ? height : width / DEFAULT_WIDTH_HEIGHT_RATIO

  return { dashboardWidth, dashboardHeight }
}
