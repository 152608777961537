import * as Constants from '../constants'

const STEP_PER_ZONE = 0.2

function getScoreAdjustment(value: number, thresholds: number[]): number {
  let upValue: number
  let downValue: number

  if (value === 0 || thresholds.includes(value)) {
    downValue = value
    upValue = value
  } else {
    const zone = getQualityZone(value, thresholds)
    if (zone === 0) {
      downValue = 0
      upValue = thresholds[zone]
    } else if (zone === thresholds.length) {
      upValue = 1
      downValue = thresholds[zone - 1]
    } else {
      downValue = thresholds[zone - 1]
      upValue = thresholds[zone]
    }
  }
  const diff = upValue - downValue
  const quote = diff <= 0 ? 0 : (value - downValue) / diff
  return quote === 0 ? 0 : (1 - quote) * STEP_PER_ZONE
}

function getParameterScore(value: number, thresholds: number[]): number {
  const zone = getQualityZone(value, thresholds)
  const score = 1 - zone * STEP_PER_ZONE
  return score === 1 ? score : score + getScoreAdjustment(value, thresholds)
}

function getQualityZone(value: number, thresholds: number[]): number {
  for (let i = 0; i < thresholds.length; i++) {
    if (value <= thresholds[i]) return i
  }
  return thresholds.length
}

// Special case when min score is 20% or less
function calculatePoorScore(scores: number[]): number {
  let sum = 0
  scores.forEach((score) => {
    if (score === 1) {
      sum += 0.1
    }
  })
  return sum
}

export interface Props {
  pm25: number
  pm1num: number
  voc: number
}

export function calculateIAQ(parameters: Props): number {
  const parameterScores = [
    getParameterScore(parameters.pm25 || 0, Constants.PM2_5_THRESHOLDS), // TODO: Discuss with Camfil how to handle missing values
    getParameterScore(parameters.pm1num || 0, Constants.PM1_THRESHOLDS),
    getParameterScore(parameters.voc || 0, Constants.VOC_THRESHOLDS),
  ]

  const minScore = Math.min(...parameterScores)
  const airQualityScore = minScore > 0.2 ? minScore : calculatePoorScore(parameterScores)

  return airQualityScore * 100
}
