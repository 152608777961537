import React from 'react'
import PoorBackgroundImage from '../../assets/bg-poor.png'
import FairBackgroundImage from '../../assets/bg-fair.png'
import AverageBackgroundImage from '../../assets/bg-average.png'
import GoodBackgroundImage from '../../assets/bg-good.png'
import ExcellentBackgroundImage from '../../assets/bg-excellent.png'
import BlueBackgroundImage from '../../assets/bg-blue.jpg'
import { Legend } from './Legend'
import { Box } from '@mui/material'
import {
  DEFAULT_THRESHOLD_AVERAGE,
  DEFAULT_THRESHOLD_EXCELLENT,
  DEFAULT_THRESHOLD_FAIR,
  DEFAULT_THRESHOLD_GOOD,
} from '../../constants/defaults'
import { VisualEffects } from './VisualEffects'
import useDashboardSize from '../../hooks/useDashboardSize'

function getBackgroundUrlFromAirQualityValue(airQuality: number | undefined): string {
  if (airQuality === undefined) {
    return BlueBackgroundImage
  }

  if (airQuality > DEFAULT_THRESHOLD_EXCELLENT) {
    return ExcellentBackgroundImage
  }

  if (airQuality > DEFAULT_THRESHOLD_GOOD) {
    return GoodBackgroundImage
  }

  if (airQuality > DEFAULT_THRESHOLD_AVERAGE) {
    return AverageBackgroundImage
  }

  if (airQuality > DEFAULT_THRESHOLD_FAIR) {
    return FairBackgroundImage
  }

  return PoorBackgroundImage
}

export interface Props {
  airQualityPercentage: number | undefined
  children?: React.ReactNode
}

export function BackgroundPoorToExcellent({ airQualityPercentage, children }: Props): JSX.Element {
  const { dashboardWidth, dashboardHeight } = useDashboardSize()

  const backgroundUrl = getBackgroundUrlFromAirQualityValue(airQualityPercentage)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'black',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: dashboardHeight,
          width: dashboardWidth,
          backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : '',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          justifyContent: 'center',
        }}
      >
        {airQualityPercentage !== undefined && <VisualEffects dashboardWidth={dashboardWidth} />}
        <Legend dashboardHeight={dashboardHeight} />
        {children}
      </Box>
    </Box>
  )
}
