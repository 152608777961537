export const PM2_5_MAX = 40
export const PM2_5_THRESHOLDS = [5, 10, 15, 25, 35]

export const PM1_MAX = 50
export const PM1_THRESHOLDS = [10, 15, 20, 30, 40]

export const VOC_MAX = 500
export const VOC_THRESHOLDS = [100, 150, 200, 300, 400]

export const CO2_MAX = 2700

const MINUTE_MILLIS = 60000
const HOUR_MILLIS = 60 * MINUTE_MILLIS
export const DATA_TIMEOUT_MILLIS = 1 * HOUR_MILLIS

export const AVERAGE_QUALITY_CIRCLE_TIMEOUT_MILLIS = 2 * MINUTE_MILLIS
