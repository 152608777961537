import { SxProps } from '@mui/material'
import { DEFAULT_WIDTH } from '../constants/defaults'
import useDashboardSize from '../hooks/useDashboardSize'
import { BoxWithPosition } from './BoxWithPosition'

export interface Props {
  positionTop: number
  positionLeft: number
  boxWidth: number
  center?: true
  sx?: SxProps
  children?: React.ReactNode
}

export function DarkBoxWithPosition({
  positionTop,
  positionLeft,
  boxWidth,
  center,
  sx,
  children,
}: Props): JSX.Element | null {
  const { dashboardWidth } = useDashboardSize()
  const borderRadius = (dashboardWidth * 8) / DEFAULT_WIDTH

  return (
    <BoxWithPosition
      positionLeft={positionLeft}
      positionTop={positionTop}
      boxWidth={boxWidth}
      center={center}
      sx={{
        background: 'rgba(16, 47, 60, 0.18)',
        borderRadius: `${borderRadius}px`,
        ...sx,
      }}
    >
      {children}
    </BoxWithPosition>
  )
}
