import { Box } from '@mui/material'
import { PercentageCircleSVG } from './PercentageCircleSVG'
import useDashboardSize from '../../hooks/useDashboardSize'

export interface Props {
  airQualityPercentage: number
  children?: React.ReactNode
}

export function AirQualityCircle({ airQualityPercentage, children }: Props): JSX.Element | null {
  const { dashboardHeight } = useDashboardSize()

  const diameter = Math.round(dashboardHeight * 0.82)
  const positionTop = Math.round(dashboardHeight / 10)

  return (
    <Box
      sx={{
        position: 'relative',
        alignSelf: 'center',
        top: positionTop,
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: `${diameter}px` }}>
          <PercentageCircleSVG percentage={airQualityPercentage} />
        </Box>
        <Box
          sx={{
            width: `${diameter * 0.8}px`,
            position: 'absolute',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}
