import { Typography } from '@mui/material'
import * as Constants from '../constants'
import { AirQualityBar } from './airQualityBar/AirQualityBar'
import { useCityConnectData } from '../hooks/useCityConnectData'
import { useTranslation } from 'react-i18next'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { getColor } from '../common-code-dashboards/utils/getColor'
import { BoxWithPosition } from '../common-code-dashboards/components/BoxWithPosition'

export function Pm1(): JSX.Element | null {
  const { t } = useTranslation()

  const { cityConnectData } = useCityConnectData()
  const { fontSizeTranslation } = useFontSizeTranslation()

  if (!cityConnectData) {
    return null
  }

  const percentagePm1 = ((cityConnectData.pm1_num || 0) / Constants.PM1_MAX) * 100

  return (
    <BoxWithPosition boxWidth={480} positionLeft={2320} positionTop={340}>
      <AirQualityBar
        id='pm1'
        label={
          <>
            PM
            <Typography component='span' fontSize={fontSizeTranslation('30px')}>
              1
            </Typography>
          </>
        }
        value={
          <>
            {cityConnectData.pm1_num ? cityConnectData.pm1_num.toFixed(0) : '-'} mil. pcs/m
            <span>&sup3;</span>
          </>
        }
        explanation={t('PM1_Explanation')}
        percentage={percentagePm1}
        color={getColor(100 - percentagePm1)}
      />
    </BoxWithPosition>
  )
}
