import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getLevelMessage } from '../common-code-dashboards/utils/levelUtils'
import { ColoredQualityLabel } from '../common-code-dashboards/components/ColoredQualityLabel'
import { BoxWithPosition } from '../common-code-dashboards/components/BoxWithPosition'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'

export interface Props {
  airQualityPercentage: number
}

export function AirQualityCircleText({ airQualityPercentage }: Props) {
  const { t } = useTranslation()

  const { fontSizeTranslation } = useFontSizeTranslation()

  return (
    <>
      <BoxWithPosition boxWidth={400} positionLeft={DEFAULT_WIDTH / 2} positionTop={503} center>
        <ColoredQualityLabel airQualityPercentage={airQualityPercentage} fontSize='64px' />
      </BoxWithPosition>
      <BoxWithPosition boxWidth={600} positionLeft={DEFAULT_WIDTH / 2} positionTop={599} center>
        <Typography
          align='center'
          sx={{
            fontSize: fontSizeTranslation('64px'),
          }}
        >
          {t('Air_Quality')}
        </Typography>
      </BoxWithPosition>

      <BoxWithPosition boxWidth={1160} positionLeft={DEFAULT_WIDTH / 2} positionTop={780} center>
        <Typography
          align='center'
          sx={{
            fontSize: fontSizeTranslation('156px'),
            fontWeight: 'bold',
            lineHeight: 1,
          }}
        >
          {getLevelMessage(airQualityPercentage, t)}
        </Typography>
      </BoxWithPosition>
    </>
  )
}
