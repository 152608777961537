import React, { useEffect, useState } from 'react'
import { useCalculatedAirQuality } from '../hooks/useCalculatedAirQuality'
import { CamfilLogo } from '../common-code-dashboards/components/CamfilLogo'
import { AirQualityCircle } from '../common-code-dashboards/components/airQualityCircle/AirQualityCircle'
import { TempHumCo2 } from './TempHumCo2'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BackgroundPoorToExcellent } from '../common-code-dashboards/components/backgroundPoorToExcellent/BackgroundPoorToExcellent'
import { AirQualityCircleText } from './AirQualityCircleText'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { Pm1 } from './Pm1'
import { Pm25 } from './Pm25'
import { VOC } from './VOC'
import { useLanguage } from '../hooks/useLanguage'

export function Dashboard(): JSX.Element {
  const { airQuality, isLoading } = useCalculatedAirQuality()
  const { t, i18n } = useTranslation()
  const { fontSizeTranslation } = useFontSizeTranslation()
  const [languageChanged, setLanguageChanged] = useState<boolean>(false)
  const { data: languageInfo } = useLanguage()

  useEffect(() => {
    if (i18n && languageInfo && !languageChanged) {
      console.log(`Changing language: ${languageInfo.language}`)
      i18n.changeLanguage(languageInfo.language)
      setLanguageChanged(true)
    }
  }, [languageInfo, i18n])

  if (airQuality) {
    console.log(`airQuality: ${JSON.stringify(airQuality)}`)
  }

  return (
    <BackgroundPoorToExcellent airQualityPercentage={airQuality}>
      <CamfilLogo />

      {airQuality !== undefined && (
        <>
          <AirQualityCircle airQualityPercentage={airQuality} />
          <AirQualityCircleText airQualityPercentage={airQuality} />

          <Pm1 />
          <Pm25 />
          <VOC />
          <TempHumCo2 />
        </>
      )}

      {!airQuality && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <Typography
              sx={{
                fontSize: fontSizeTranslation('60px'),
                fontWeight: 'bold',
                lineHeight: 1,
              }}
            >
              {t('No_Data')}
            </Typography>
          )}
        </Box>
      )}
    </BackgroundPoorToExcellent>
  )
}
