import { Box, SxProps } from '@mui/material'
import { DEFAULT_WIDTH } from '../constants/defaults'
import useDashboardSize from '../hooks/useDashboardSize'

export interface Props {
  positionTop: number
  positionLeft: number
  boxWidth: number
  center?: true
  sx?: SxProps
  children?: React.ReactNode
}

export function BoxWithPosition({
  positionTop,
  positionLeft,
  boxWidth,
  center,
  sx,
  children,
}: Props): JSX.Element | null {
  const { dashboardWidth } = useDashboardSize()

  const width = (dashboardWidth * boxWidth) / DEFAULT_WIDTH
  const top = (dashboardWidth * positionTop) / DEFAULT_WIDTH
  const left = center
    ? (dashboardWidth * (positionLeft - boxWidth / 2)) / DEFAULT_WIDTH
    : (dashboardWidth * positionLeft) / DEFAULT_WIDTH

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        position: 'absolute',
        top,
        left,
        width,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
