import { Grid, Typography } from '@mui/material'
import Box from '@mui/system/Box'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_AVERAGE_COLOR,
  DEFAULT_EXECELLENT_COLOR,
  DEFAULT_FAIR_COLOR,
  DEFAULT_GOOD_COLOR,
  DEFAULT_POOR_COLOR,
} from '../../constants/defaults'
import { useFontSizeTranslation } from '../../hooks/useFontSizes'

export interface Props {
  dashboardHeight: number
}

export function Legend({ dashboardHeight }: Props): JSX.Element {
  const { t } = useTranslation()

  const { fontSizeTranslation } = useFontSizeTranslation()
  const lineHeight = 2
  const barHeight = Math.round(dashboardHeight / 70)

  const gradient = `0.25turn, ${DEFAULT_POOR_COLOR}, ${DEFAULT_FAIR_COLOR}, ${DEFAULT_AVERAGE_COLOR}, ${DEFAULT_GOOD_COLOR}, ${DEFAULT_EXECELLENT_COLOR}`
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
      }}
    >
      <Grid container justifyContent='space-around'>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('36px'),
            lineHeight,
          }}
        >
          {t('Poor')}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('36px'),
            lineHeight,
          }}
        >
          {t('Fair')}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('36px'),
            lineHeight,
          }}
        >
          {t('Average')}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('36px'),
            lineHeight,
          }}
        >
          {t('Good')}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeTranslation('36px'),
            lineHeight,
          }}
        >
          {t('Excellent')}
        </Typography>
      </Grid>

      <Box
        sx={{
          background: `linear-gradient(${gradient})`,
          height: barHeight,
        }}
      ></Box>
    </Box>
  )
}
