import Box from '@mui/material/Box'
import CamfilLogoImg from '../assets/camfil-logo-white.png'
import useDashboardSize from '../hooks/useDashboardSize'

export function CamfilLogo(): JSX.Element {
  const { dashboardWidth, dashboardHeight } = useDashboardSize()
  const width = Math.round(dashboardHeight * 0.28)
  const positionTop = Math.round(dashboardHeight * 0.055)
  const positionLeft = Math.round(dashboardWidth * 0.045)

  return (
    <Box
      component='img'
      sx={{
        position: 'absolute',
        top: positionTop,
        left: positionLeft,
        width,
      }}
      src={CamfilLogoImg}
    />
  )
}
