import useSWR from 'swr'
import { useEffect, useState } from 'react'
import { DATA_TIMEOUT_MILLIS } from '../constants'
import { useQuery } from '../common-code-react/hooks/useQuery'
import { parseUrlQueryParams } from '../utils/parseUrlQueryParams'
import { CityConnectDeviceData } from '../common-code-react/common-types/products/cityConnect'
import { fetcher } from '../common-code-react/utils/fetcher'

export function useCityConnectData() {
  const [cityConnectData, setCityConnectData] = useState<CityConnectDeviceData | undefined>()

  const query = useQuery()
  const queryParams = parseUrlQueryParams(query)

  const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || '' : ''
  const url = `/api/measurements/latest/${queryParams.dashboardCode || ''}`
  const fullUrl = encodeURI(`${baseUrl}${url}`)

  const { data, error } = useSWR(queryParams.dashboardCode ? fullUrl : null, fetcher<CityConnectDeviceData>(), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 30000,
  })

  useEffect(() => {
    if (data) {
      console.log(`deviceData: ${JSON.stringify(data)}`)

      const time = new Date((data.utc || 0) * 1000)
      const now = new Date()
      const millisDiff = Math.abs(now.getTime() - time.getTime())
      if (millisDiff < DATA_TIMEOUT_MILLIS) {
        setCityConnectData({
          ...data,
          voci: queryParams.voc ? queryParams.voc : data.voci,
          // eslint-disable-next-line camelcase
          pm2_5: queryParams.pm25 ? queryParams.pm25 : data.pm2_5,
          // eslint-disable-next-line camelcase
          pm1_num: queryParams.pm1 ? queryParams.pm1 : data.pm1_num,
        })
        console.log('Timeout started')
        const timeoutId = setTimeout(() => {
          console.log('Timeout! cityConnectData cleared')
          setCityConnectData(undefined)
        }, DATA_TIMEOUT_MILLIS)
        return () => {
          console.log('Timeout stopped')
          clearTimeout(timeoutId)
        }
      }
    }
  }, [data])

  return {
    cityConnectData: cityConnectData
      ? cityConnectData
      : queryParams.pm1 && queryParams.pm25 && queryParams.voc
      ? ({
          deviceId: 'Manual',
          utc: Math.round(Date.now() / 1000),
          // eslint-disable-next-line camelcase
          pm2_5: queryParams.pm25,
          // eslint-disable-next-line camelcase
          pm1_num: queryParams.pm1,
          voci: queryParams.voc,
          co2: 0,
          temp: 0,
          hum: 0,
          airquality: 0,
          pm1: 0,
          pm10: 0,
          pdiff: 0,
          rpm: 0,
        } as CityConnectDeviceData)
      : undefined,
    isLoading: !error && !data,
    isError: error,
  }
}
