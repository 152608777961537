import { Typography } from '@mui/material'
import * as Constants from '../constants'
import { AirQualityBar } from './airQualityBar/AirQualityBar'
import { useCityConnectData } from '../hooks/useCityConnectData'
import { useTranslation } from 'react-i18next'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { getColor } from '../common-code-dashboards/utils/getColor'
import { BoxWithPosition } from '../common-code-dashboards/components/BoxWithPosition'

export function Pm25(): JSX.Element | null {
  const { t } = useTranslation()

  const { cityConnectData } = useCityConnectData()
  const { fontSizeTranslation } = useFontSizeTranslation()

  if (!cityConnectData) {
    return null
  }

  const percentagePm25 = ((cityConnectData.pm2_5 || 0) / Constants.PM2_5_MAX) * 100

  return (
    <BoxWithPosition boxWidth={480} positionLeft={2320} positionTop={610}>
      <AirQualityBar
        id='pm25'
        label={
          <>
            PM
            <Typography component='span' fontSize={fontSizeTranslation('30px')}>
              2.5
            </Typography>
          </>
        }
        value={
          <>
            <>
              {cityConnectData.pm2_5 ? cityConnectData.pm2_5.toFixed(0) : '-'} <span>&#181;</span>
              g/m
              <span>&sup3;</span>
            </>
          </>
        }
        explanation={t('PM2_5_Explanation')}
        percentage={percentagePm25}
        color={getColor(100 - percentagePm25)}
      />
    </BoxWithPosition>
  )
}
