import { Box, Typography } from '@mui/material'
import { PercentageBarSVG } from './PercentageBarSVG'
import { useFontSizeTranslation } from '../../common-code-dashboards/hooks/useFontSizes'
import useDashboardSize from '../../common-code-dashboards/hooks/useDashboardSize'

interface Props {
  id: string
  label: string | JSX.Element
  value: string | JSX.Element
  explanation: string
  percentage: number
  color: string
}

export function AirQualityBar({ id, label, value, explanation, percentage, color }: Props) {
  const { dashboardHeight } = useDashboardSize()
  const { fontSizeTranslation } = useFontSizeTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={fontSizeTranslation('40px')} lineHeight={0}>
          {label}
        </Typography>
        <Typography fontSize={fontSizeTranslation('30px')}>{value}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: dashboardHeight * 0.05,
          alignItems: 'center',
        }}
      >
        <PercentageBarSVG id={id} percentage={percentage} color={color} />
      </Box>
      <Typography
        sx={{
          fontSize: fontSizeTranslation('36px'),
          lineHeight: 1.2,
        }}
      >
        {explanation}
      </Typography>
    </Box>
  )
}
