export interface Props {
  id: string
  percentage: number
  color: string
}

export function PercentageBarSVG({ id, percentage, color }: Props) {
  percentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage

  const width = 100
  const height = width * 0.1278
  const strokeWidth = width * 0.0075
  const viewBoxWidth = Math.ceil(width + strokeWidth)
  const viewBoxHeight = Math.ceil(height + strokeWidth)
  const viewBox = `${-strokeWidth / 2} 0 ${viewBoxWidth} ${viewBoxHeight}`
  const rectY = (viewBoxHeight - height) / 2
  const percentageWidth = (percentage * width) / 100
  const mask = `hideOverflow${id}`
  const clipPath = `clipPath${id}`
  const filter = `shadowFilter${id}`

  return (
    <svg width='100%' height='100%' viewBox={viewBox}>
      <defs>
        <filter id={filter} x='0' y='0'>
          <feGaussianBlur in='SourceGraphic' stdDeviation={strokeWidth * 2} />
        </filter>
        <clipPath id={clipPath}>
          <rect y={rectY} width={percentageWidth} height={height} rx={height / 2} />
        </clipPath>
        <mask id={mask}>
          <rect y={rectY} width={width} height={height} fill='black' />
          <circle cx={height / 2} cy='50%' r={height / 2} fill='white' clipPath={`url(#${clipPath})`} />
          {percentageWidth - height / 2 > 0 && (
            <rect
              y={rectY}
              x={height / 2}
              width={percentageWidth - height / 2}
              height={height}
              fill='white'
              clipPath={`url(#${clipPath})`}
            />
          )}
        </mask>
      </defs>
      <rect
        y={rectY}
        width={percentageWidth}
        height={height}
        rx={height / 2}
        fill={color}
        mask={`url(#${mask})`}
        stroke='black'
        strokeWidth={strokeWidth * 2}
        filter={`url(#${filter})`}
      />
      <rect
        y={rectY}
        width={width}
        height={height}
        rx={height / 2}
        fill='none'
        stroke='white'
        strokeWidth={`${strokeWidth}px`}
      />
    </svg>
  )
}
