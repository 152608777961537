import { DEFAULT_WIDTH } from '../constants/defaults'
import useDashboardSize from './useDashboardSize'
interface FontSizeTranslation {
  fontSizeTranslation: (fontSize: string) => string
}

export function useFontSizeTranslation(): FontSizeTranslation {
  const { dashboardWidth } = useDashboardSize()

  function fontSizeTranslation(fontSize: string): string {
    try {
      const numberValue = parseFloat(fontSize.split('px')[0])

      const newValue = (numberValue * dashboardWidth) / DEFAULT_WIDTH

      return `${newValue}px`
    } catch (err) {
      console.log(`fontSizeTranslation err: ${JSON.stringify(err)}`)
      // Fallback to a very small font so we can see an error
      return '12px'
    }
  }

  return {
    fontSizeTranslation,
  }
}
