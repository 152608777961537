import './App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Dashboard } from './components/Dashboard'
import { BrowserRouter as Router } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Brandon-Grotesque',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: 'white',
    },
  },
})

// Needed to avoid white space when switching between portrait and landscape
// See e.g. https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
function setDocHeight(height: number) {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${height}px`)
}

function App() {
  const { height } = useWindowSize()
  setDocHeight(height)

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Dashboard />
      </Router>
    </ThemeProvider>
  )
}

export default App
