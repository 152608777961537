import { Typography } from '@mui/material'
import * as Constants from '../constants'
import { AirQualityBar } from './airQualityBar/AirQualityBar'
import { useCityConnectData } from '../hooks/useCityConnectData'
import { useTranslation } from 'react-i18next'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { getColor } from '../common-code-dashboards/utils/getColor'
import { BoxWithPosition } from '../common-code-dashboards/components/BoxWithPosition'

export function VOC(): JSX.Element | null {
  const { t } = useTranslation()

  const { cityConnectData } = useCityConnectData()
  const { fontSizeTranslation } = useFontSizeTranslation()

  if (!cityConnectData) {
    return null
  }

  const percentageVoc = ((cityConnectData.voci || 0) / Constants.VOC_MAX) * 100

  return (
    <BoxWithPosition boxWidth={480} positionLeft={2320} positionTop={880}>
      <AirQualityBar
        id='VOC'
        label='VOC'
        value={
          <>
            {cityConnectData.voci ? cityConnectData.voci.toFixed(0) : '-'} voc
            <Typography
              component='span'
              sx={{
                verticalAlign: 'top',
                fontSize: fontSizeTranslation('16px'),
                textTransform: 'uppercase',
              }}
            >
              index
            </Typography>
          </>
        }
        explanation={t('VOC_Explanation')}
        percentage={percentageVoc}
        color={getColor(100 - percentageVoc)}
      />
    </BoxWithPosition>
  )
}
