export interface QueryParams {
  dashboardCode: string | null
  pm25: number | null
  pm1: number | null
  voc: number | null
  lang: string | null
}

export function parseUrlQueryParams(query: URLSearchParams): QueryParams {
  const dashboardCode = query.get('dc')

  const pm25String = query.get('pm25')
  const pm25 = pm25String ? Number.parseInt(pm25String) : null
  const pm1String = query.get('pm1')
  const pm1 = pm1String ? Number.parseInt(pm1String) : null
  const vocString = query.get('voc')
  const voc = vocString ? Number.parseInt(vocString) : null

  return {
    dashboardCode,
    pm25,
    pm1,
    voc,
    lang: query.get('lang'),
  }
}
