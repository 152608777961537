import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fi from './fi.json'
import fr from './fr.json'
import it from './it.json'
import nl from './nl.json'
import nlBE from './nl-BE.json'
import sv from './sv.json'

const resources = {
  de,
  en,
  es,
  fi,
  fr,
  it,
  nl,
  'nl-BE': nlBE,
  sv,
}

const detectionOptions = {
  order: ['querystring'],
  lookupQuerystring: 'lang',
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: detectionOptions,
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  })

export default i18n
