export const RATIO = 16 / 9

export const DEFAULT_WIDTH = 2880
export const DEFAULT_HEIGHT = DEFAULT_WIDTH / RATIO

export const DEFAULT_THRESHOLD_FAIR = 20
export const DEFAULT_THRESHOLD_AVERAGE = 40
export const DEFAULT_THRESHOLD_GOOD = 60
export const DEFAULT_THRESHOLD_EXCELLENT = 80

export const DEFAULT_POOR_COLOR = '#A86DE2'
export const DEFAULT_FAIR_COLOR = '#7A86FC'
export const DEFAULT_AVERAGE_COLOR = '#45E599'
export const DEFAULT_GOOD_COLOR = '#48FFC6'
export const DEFAULT_EXECELLENT_COLOR = '#00FFF5'

export const DARK_POOR_COLOR = '#A86DE2'
export const DARK_FAIR_COLOR = '#7A86FC'
export const DARK_AVERAGE_COLOR = '#1BBC6F'
export const DARK_GOOD_COLOR = '#03D9CC'
export const DARK_EXECELLENT_COLOR = '#00C4EF'
